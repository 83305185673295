@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  margin: $tokenSpacingLarge4X $tokenSpacingSmall2X;
  margin-bottom: $tokenSpacingLarge6X;

  @media (--breakpointLargeFrom) {
    margin: $tokenSpacingLarge5X 0;
    margin-bottom: calc($tokenSpacingLarge9X + $tokenSpacingSmall2X);
  }

  & h2 {
    margin-bottom: $tokenSpacingLarge1X;

    @media (--breakpointLargeFrom) {
      margin-bottom: $tokenSpacingLarge3X;
    }
  }

  & .services {
    align-items: start;
    display: grid;
    grid-gap: $tokenSpacingDefault;
    grid-template-columns: 1fr;

    @media (--breakpointMediumFrom) {
      grid-template-columns: 1fr 1fr;
    }

    @media (--breakpointLargeFrom) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  & .products {
    align-items: start;
    display: grid;
    grid-gap: $tokenSpacingDefault;
    grid-template-columns: 1fr;

    @media (--breakpointLargeFrom) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

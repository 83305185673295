@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  justify-self: center;
  margin: $tokenSpacingLarge6X $tokenSpacingSmall2X;
  margin-bottom: $tokenSpacingLarge2X;

  @media (--breakpointMediumFrom) {
    margin: $tokenSpacingLarge6X $tokenSpacingDefault;
    margin-bottom: calc($tokenSpacingLarge9X + $tokenSpacingSmall2X);
    max-width: $tokenSizingBreakpointMediumFrom;
  }

  @media (--breakpointLargeFrom) {
    max-width: $tokenSizingBreakpointMediumTo;
    min-width: $tokenSizingBreakpointMediumTo;
  }

  & h2 {
    margin-bottom: $tokenSpacingLarge3X;

    @media (--breakpointLargeFrom) {
      margin-bottom: $tokenSpacingLarge5X;
    }
  }
}

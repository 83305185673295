@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  --borderRadius: 0;
  --padding: 0 $tokenSpacingDefault $tokenSpacingLarge2X;
  --margin: 0 -$tokenSpacingDefault 0 -$tokenSpacingDefault;

  border-radius: var(--borderRadius);
  margin: var(--margin);
  padding: var(--padding);

  & .service fieldset:nth-child(2) {
    margin-top: $tokenSpacingLarge3X;
  }
}

.location,
.service,
.date,
.petTypes {
  margin-bottom: $tokenSpacingElementFormFieldDefault;

  & [data-faux-role='formField'],
  & [data-faux-role='fieldset'] {
    margin-bottom: $tokenSpacingSmall2X;
  }
}

@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointLarge2X (min-width: 1190px);
@custom-media --breakpointLarge (min-width: $tokenSizingBreakpointMediumTo);
@custom-media --breakpointMedium (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  --padding: $tokenSpacingElementFormTextInputPadding;

  align-items: center;
  background-color: $tokenColorsBrandTertiaryDefault;
  display: flex;
  justify-content: center;
  padding: var(--padding);

  & form {
    max-width: $tokenSizingLayoutGridMaxWidth;
  }
}

.rootRounded {
  border-radius: $tokenSpacingLarge2X;
}

.sticky {
  --padding: $tokenSpacingElementFormTextInputPadding;
  --dropShadowBlur: $tokenSpacingSmall4X;
  --dropShadowSpread: calc($tokenSpacingSmall4X + $tokenSpacingSmall3X);

  align-items: center;
  background-color: $tokenColorsBrandTertiaryDefault;
  /* stylelint-disable-next-line function-disallowed-list */
  box-shadow: 0 var(--dropShadowBlur) var(--dropShadowSpread) rgba(0 0 0 / 0.15);
  display: flex;
  justify-content: center;
  margin-bottom: $tokenSpacingLarge3X;
  padding: var(--padding);
  position: fixed;
  top: 0;
  width: 100%;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 2;

  & form {
    max-width: $tokenSizingLayoutGridMaxWidth;
  }
}

& .expandedMobileHeader {
  margin-top: $tokenSpacingDefault;

  & h1 {
    font-family: $tokenFontFamiliesHeading;
    font-size: $tokenFontSizesLarge4X;
    text-align: center;
  }

  & .headerDescription {
    color: $tokenColorsGreyscaleDark2X;
    margin-top: $tokenSpacingDefault;
    text-align: center;
  }
}

.formContainer {
  display: grid;
  gap: $tokenSpacingLarge1X;
  grid-template-columns: 1fr;

  @media (--breakpointMedium) {
    & button {
      height: $tokenSpacingLarge4X;
    }
  }

  @media (--breakpointLarge) {
    gap: $tokenSpacingDefault;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr $tokenBorderWidthSmall1X auto;
  }

  @media (--breakpointLarge2X) {
    grid-template-columns: 1fr $tokenBorderWidthSmall1X auto;
  }

  & .divider {
    background-color: $tokenColorsGreyscaleLight6X;
    display: none;
    width: $tokenBorderWidthSmall1X;

    @media (--breakpointLarge) {
      display: flex;
    }
  }

  & .searchForm {
    display: grid;
    gap: $tokenSpacingSmall1X;
    grid-template-columns: 1fr 1fr;

    & [data-faux-role='formField'] {
      margin-bottom: 0;

      @media (--breakpointMedium) {
        & input {
          height: $tokenSpacingLarge4X;
        }
      }
    }

    @media (--breakpointLarge) {
      gap: $tokenSpacingSmall2X;
      grid-template-columns: 1fr 1fr 1.4fr 1fr auto;
    }

    & .breakPoints,
    & .bookingType,
    & .dates,
    & .location {
      grid-column: 1 / 3;

      @media (--breakpointMedium) {
        grid-column: unset;
      }
    }

    & .bookingType {
      grid-column: 1 / 3;

      & label {
        background-color: $tokenColorsGreyscaleWhite;
      }
    }

    & .searchBtn {
      grid-column: 1 / 3;

      @media (--breakpointLarge) {
        display: none;
        grid-column: 5 / 6;
      }

      @media (--breakpointLarge2X) {
        display: inline;
      }
    }

    & .searchIconButton {
      display: none;

      @media (--breakpointLarge) {
        display: inline;
      }

      @media (--breakpointLarge2X) {
        display: none;
      }

      & button {
        background-color: $tokenColorsBrandDefault;
        border-radius: $tokenBorderRadiusLarge7X;
        color: $tokenColorsGreyscaleWhite;

        &:disabled {
          background-color: $tokenColorsGreyscaleLight6X;
        }
      }
    }
  }

  & .filterRoot {
    align-items: center;
    display: flex;
    justify-content: center;
    -ms-overflow-style: none; /* IE and Edge */
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */

    @media (--breakpointMedium) {
      overflow-x: unset;
    }

    @media (--breakpointLarge) {
      margin-top: 0;
    }

    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }

    & .filters {
      align-items: center;
      display: flex;
      gap: $tokenSpacingSmall2X;
      -ms-overflow-style: none; /* IE and Edge */
      overflow-x: scroll;
      scrollbar-width: none; /* Firefox */
      white-space: nowrap;

      @media (--breakpointMedium) {
        overflow-x: unset;
      }

      @media (--breakpointLarge) {
        margin-top: 0;
      }

      &::-webkit-scrollbar {
        display: none;
        width: 0;
      }
    }
  }
}

.dynamicHeaderContainer {
  display: grid;
  grid-template-columns: 1fr auto;
}

.slideInAnimation {
  animation: slideIn 0.4s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);

.root {
  border-radius: $tokenBorderRadiusLarge2X;
  color: $tokenColorsTextBody;
  display: grid;
  grid-gap: $tokenSpacingDefault;
  grid-template-columns: $tokenSpacingLarge2X 1fr;
  padding: $tokenSpacingSmall2X;
  transition: $tokenMotionGlobalTransition;

  & .icon {
    display: flex;

    & svg {
      height: $tokenSpacingLarge2X;
      width: $tokenSpacingLarge2X;
    }
  }

  & .content {
    display: grid;
    grid-gap: $tokenSpacingSmall2X;
    grid-template-columns: auto 1fr;

    & div {
      align-items: center;
      display: flex;
    }
  }

  & .title {
    font-size: $tokenFontSizesLarge1X;
    font-weight: $tokenFontWeightsSemiBold;
    margin-bottom: $tokenSpacingSmall3X;
  }

  & .description {
    color: $tokenColorsGreyscaleDark2X;
    font-size: $tokenFontSizesSmall1X;
  }
}

@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointExtraLargeFrom (min-width: $tokenSizingBreakpointLarge2XFrom);

.root {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $tokenSpacingSmall2X;
  justify-content: center;
  margin-bottom: 0;

  @media (--breakpointMediumFrom) {
    margin-bottom: $tokenSpacingSmall2X;
  }

  & img {
    margin-top: $tokenSpacingSmall3X;
    width: calc($tokenSpacingLarge8X + $tokenSpacingSmall3X);

    @media (--breakpointLargeFrom) {
      width: calc($tokenSpacingLarge9X + $tokenSpacingSmall1X);
    }
  }

  & p {
    color: $tokenColorsGreyscaleDark3X;
    font-size: $tokenFontSizesSmall2X;

    @media (--breakpointMediumFrom) {
      font-size: $tokenFontSizesDefault;
    }
  }

  & svg {
    color: $tokenColorsWarningDefault;
  }

  & .review {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: $tokenSpacingSmall3X;
  }

  & .rate {
    color: $tokenColorsWarningDefault;
    font-family: $tokenFontFamiliesHeading;
    font-size: $tokenFontSizesDefault;

    @media (--breakpointLargeFrom) {
      font-size: $tokenFontSizesLarge1X;
    }
  }

  & .winner {
    background-color: $tokenColorsGreyscaleWhite;
    border-radius: $tokenSpacingSmall3X;
    padding: $tokenSpacingSmall3X calc($tokenSpacingSmall3X + $tokenSpacingSmall4X);

    @media (--breakpointLargeFrom) {
      background-color: $tokenColorsGreyscaleLight6X;
    }

    & p {
      font-size: $tokenFontSizesSmall2X;

      @media (--breakpointLargeFrom) {
        font-size: $tokenFontSizesSmall1X;
      }
    }
  }
}

@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointExtraLargeFrom (min-width: $tokenSizingBreakpointLarge2XFrom);

.root {
  --margin: 0 $tokenSpacingSmall2X;

  display: flex;
  flex-direction: column-reverse;
  justify-self: center;
  margin: var(--margin);
  margin-bottom: $tokenSpacingLarge6X;

  @media (--breakpointMediumFrom) {
    --margin: 0 calc((-1 * 10%));

    margin-bottom: calc($tokenSpacingLarge9X + $tokenSpacingSmall2X);
  }

  @media (--breakpointLargeFrom) {
    align-items: center;
    flex-direction: row;
  }

  & svg {
    color: $tokenColorsBrandDefault;
  }

  & img {
    height: auto;
    min-width: calc(($tokenSpacingLarge7X * 4) - $tokenSpacingLarge1X);
    object-fit: cover;

    @media (--breakpointMediumFrom) {
      min-width: calc(($tokenSpacingLarge8X * 5) - $tokenSpacingLarge2X);
    }

    @media (--breakpointExtraLargeFrom) {
      min-width: calc(($tokenSpacingLarge9X * 5) - $tokenSpacingLarge1X);
    }
  }

  & .contentWrapper {
    align-items: flex-start;
    background-color: $tokenColorsGreyscaleWhite;
    border-radius: $tokenSpacingLarge2X 0 0 $tokenSpacingLarge2X;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: $tokenSpacingLarge3X;

    @media (--breakpointLargeFrom) {
      background-color: $tokenColorsGreyscaleLight5X;
      max-width: 50%;
      min-width: 50%;
      padding: $tokenSpacingLarge5X;
    }
  }

  & .whiteContentWrapper {
    background-color: $tokenColorsGreyscaleWhite;
  }

  & .headingWrapper {
    align-items: center;
    display: flex;
    padding-bottom: $tokenSpacingLarge3X;

    @media (--breakpointMediumFrom) {
      padding-bottom: $tokenSpacingLarge1X;
      padding-left: $tokenSpacingDefault;
    }

    @media (--breakpointLargeFrom) {
      padding-left: 0;
    }

    & svg {
      min-height: $tokenSpacingLarge4X;
      min-width: $tokenSpacingLarge4X;
    }

    & h2 {
      text-align: left;
    }
  }

  & .iconContainer {
    padding-right: $tokenSpacingLarge1X;
  }

  & .trustBuilders {
    display: flex;
    flex-direction: column;
    gap: $tokenSpacingLarge1X;

    & .trustBuilderWrapper {
      align-items: flex-start;
      display: flex;
      width: 100%;

      & svg {
        min-height: $tokenSpacingLarge1X;
        min-width: $tokenSpacingLarge1X;
      }

      & .trustBuilder {
        font-size: $tokenFontSizesDefault;
        margin-left: $tokenSpacingLarge1X;

        @media (--breakpointMediumFrom) {
          font-size: $tokenFontSizesLarge1X;
        }
      }
    }
  }
}

.oldRoot {
  & img {
    aspect-ratio: 327/188;
    border-radius: $tokenSpacingLarge1X;
    height: auto;
    min-width: calc(($tokenSpacingLarge7X * 4) - $tokenSpacingLarge1X);
    object-fit: cover;

    @media (--breakpointMediumFrom) {
      min-width: calc(($tokenSpacingLarge8X * 5) - $tokenSpacingLarge2X);
    }

    @media (--breakpointLargeFrom) {
      aspect-ratio: 615/473;
    }

    @media (--breakpointExtraLargeFrom) {
      aspect-ratio: 615/473;
      min-width: calc(($tokenSpacingLarge9X * 5) - $tokenSpacingLarge1X);
    }
  }
}

@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointExtraLargeFrom (min-width: $tokenSizingBreakpointLarge2XFrom);

.root {
  --margin: $tokenSpacingLarge3X -$tokenSpacingDefault;
  --padding: 0 $tokenSpacingLarge4X;

  background-color: $tokenColorsInteractionDisabledBackground;
  border-radius: $tokenSpacingLarge2X;
  display: grid;
  grid-gap: $tokenSpacingSmall3X;
  grid-template-columns: 1fr;
  margin: var(--margin);
  margin-bottom: $tokenSpacingLarge6X;
  padding: var(--padding);

  @media (--breakpointLargeFrom) {
    --margin: $tokenSpacingLarge6X calc(-1 * 12.5%);
    --padding: 0 calc($tokenSpacingLarge8X + $tokenSpacingSmall1X);

    grid-gap: $tokenSpacingLarge4X;
    grid-template-columns: 1fr 1fr;
    margin-bottom: calc($tokenSpacingLarge9X + $tokenSpacingSmall2X);
  }

  & .details {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $tokenSpacingDefault;
    justify-content: center;
    margin: $tokenSpacingLarge2X 0;
    text-align: center;

    & .content {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: $tokenSpacingSmall1X;

      & .tag {
        background-color: $tokenColorsBrandLight4X;
        border-radius: $tokenSpacingSmall1X;
        color: $tokenColorsBrandDark1X;
        padding: 0 $tokenSpacingSmall1X;
      }
    }
  }

  & .image {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

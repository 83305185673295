@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);

/**
  * 1. Overrides the `text-align: right;` rule set on the parent
  *    FiltersHeader component's container.
  */

.root {
  & input {
    padding-right: $tokenSpacingElementFormTextInputPadding !important;
  }

  & label {
    text-align: left; /* 1 */
  }
}

& .suggestionsMaxWidth {
  width: 100%;

  @media (--breakpointLargeFrom) {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    width: 130%;
  }
}

.suggestions {
  $paddingSides: $tokenSpacingDefault;

  position: relative;
  text-align: left; /* 1 */

  & > div {
    $buttonNoResultsPadding: $tokenSpacingSmall1X $paddingSides;

    background-color: $tokenColorsGreyscaleWhite;
    border-radius: $tokenBorderRadiusDefault;

    /* TODO(DS-269): Replace with design-system box shadow token */
    /* stylelint-disable-next-line function-disallowed-list */
    box-shadow: 0 0 rem(8) rgba(0 0 0 / 0.15);
    padding-bottom: $tokenSpacingDefault;
    padding-top: $tokenSpacingSmall1X;
    position: absolute;
    top: $tokenSpacingSmall2X;
    width: 100%;
    z-index: 1;

    & ul {
      & li {
        &:not(:last-child) {
          margin-bottom: $tokenSpacingInteractionTapTarget;
        }

        /* TODO(WEB-882): remove after updating tooling-config */
        /* stylelint-disable a11y/media-prefers-reduced-motion */
        & button {
          display: block;
          overflow: hidden;
          padding: $buttonNoResultsPadding;
          text-overflow: ellipsis;
          transition: $tokenMotionGlobalTransition;

          /* TODO(WEB-882): remove after updating tooling-config */
          /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
          transition-property: background-color, $tokenMotionFocusRingProperty;
          white-space: nowrap;
          width: 100%;

          &:focus,
          &:hover {
            background-color: $tokenColorsGreyscaleLight5X;
          }

          & mark {
            /* TODO(WEB-882): remove after updating tooling-config */
            /* stylelint-disable-next-line property-disallowed-list */
            background: none;
            color: $tokenColorsTextBody;
            font-weight: $tokenFontWeightsBold;
          }
        }

        /* TODO(WEB-882): remove after updating tooling-config */
        /* stylelint-enable a11y/media-prefers-reduced-motion */
      }
    }

    & p {
      padding: $buttonNoResultsPadding;
    }
  }

  & .vendorLogo {
    margin-top: $tokenSpacingSmall1X;
    padding-left: $paddingSides;
    padding-right: $paddingSides;
    text-align: right;

    & img {
      display: inline;
    }
  }
}

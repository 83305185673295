@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointExtraLargeFrom (min-width: $tokenSizingBreakpointLarge2XFrom);

.root {
  --margin: 0 -$tokenSpacingDefault;

  background-color: $tokenColorsGreyscaleLight5X;
  border-radius: $tokenSpacingLarge2X;
  display: flex;
  flex-direction: column;
  gap: $tokenSpacingDefault;
  margin: var(--margin);
  margin-top: $tokenSpacingSmall2X;
  padding-top: $tokenSpacingLarge2X;

  @media (--breakpointMediumFrom) {
    --margin: 0 calc((-1 * 12.5%));

    margin-top: $tokenSpacingLarge2X;
    padding-top: $tokenSpacingLarge2X;
  }

  @media (--breakpointLargeFrom) {
    background-color: $tokenColorsGreyscaleWhite;
    margin-top: $tokenSpacingLarge1X;
  }

  & .mobileSearchWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $tokenSpacingSmall1X;
    margin-bottom: $tokenSpacingSmall1X;
  }

  & .titleContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $tokenSpacingDefault;
    padding: 0 $tokenSpacingDefault;

    @media (--breakpointLargeFrom) {
      gap: $tokenSpacingSmall3X;
      margin-bottom: $tokenSpacingSmall2X;
      padding: 0;
    }

    & h2 {
      --margin: 0 $tokenSpacingLarge3X;

      margin: var(--margin);

      @media (--breakpointLargeFrom) {
        --margin: 0 0;
      }
    }

    & h1 {
      color: $tokenColorsGreyscaleDark2X;
      font-size: $tokenFontSizesLarge1X;
      font-weight: $tokenFontWeightsRegular;
      text-align: center;

      @media (--breakpointMediumFrom) {
        font-size: $tokenFontSizesLarge2X;
      }
    }
  }

  & .imageWrapper {
    width: 100%;

    @media (--breakpointMediumFrom) {
      max-height: calc($tokenSpacingLarge4X * 11);
      overflow: hidden;
    }

    @media (--breakpointLargeFrom) {
      grid-column: 1 / 13;
    }

    & img {
      border-radius: $tokenSpacingLarge2X;
    }
  }
}

.details {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: $tokenSpacingLarge1X calc($tokenSpacingLarge4X + $tokenSpacingSmall1X);
  justify-content: center;
  margin-top: $tokenSpacingLarge1X;

  & .detail {
    align-items: center;
    display: flex;
    flex-direction: column;

    & .detailTitle {
      color: $tokenColorsGreyscaleDark3X;
      font-size: $tokenFontSizesLarge1X;
      font-weight: $tokenFontWeightsBold;

      @media (--breakpointMediumFrom) {
        font-size: $tokenFontSizesLarge3X;
      }
    }

    & .detailDescription {
      color: $tokenColorsGreyscaleDark2X;
      font-size: $tokenFontSizesSmall1X;

      @media (--breakpointMediumFrom) {
        font-size: $tokenFontSizesLarge1X;
      }
    }
  }
}

@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  background-color: $tokenColorsBrandLight5X;
  border-radius: $tokenBorderRadiusLarge1X;
  color: $tokenColorsBrandDefault;
  font-size: $tokenFontSizesSmall1X;
  font-weight: $tokenFontWeightsSemiBold;
  gap: $tokenSpacingSmall3X;
  max-width: fit-content;
  min-width: fit-content;
  padding: 0 $tokenSpacingSmall2X;
}

@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  --margin: $tokenSpacingLarge3X -$tokenSpacingDefault;

  display: flex;
  flex-direction: column;
  gap: $tokenSpacingLarge1X;
  margin: var(--margin);

  @media (--breakpointLargeFrom) {
    --margin: $tokenSpacingLarge6X calc(-1 * 10%);

    gap: $tokenSpacingLarge3X;
    margin-bottom: calc($tokenSpacingLarge9X + $tokenSpacingSmall2X);
  }

  & .headingContainer {
    --margin: 0 $tokenSpacingLarge4X;

    margin: var(--margin);

    @media (--breakpointLargeFrom) {
      --margin: 0;
    }
  }

  & .productsContainer {
    display: grid;
    gap: $tokenSpacingLarge1X;
    grid-template-columns: 1fr;

    @media (--breakpointLargeFrom) {
      grid-template-columns: repeat(3, 1fr);
    }

    & .product {
      align-items: center;
      border-radius: $tokenSpacingLarge2X;
      display: flex;
      flex-direction: column;
      gap: $tokenSpacingDefault;
      justify-content: space-between;
      margin-left: $tokenSpacingLarge1X;
      margin-right: $tokenSpacingLarge1X;
      padding-top: $tokenSpacingLarge2X;

      @media (--breakpointLargeFrom) {
        margin-left: 0;
        margin-right: 0;
      }

      & .logoButtonContainer {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: $tokenSpacingDefault;
        justify-content: flex-end;
        min-height: $tokenSpacingLarge7X;
      }
    }

    & .petChemist {
      background-color: $tokenColorsPetChemistSecondary;
    }

    & .waggly {
      background-color: $tokenColorsWagglySecondary;
    }

    & .sash {
      background-color: $tokenColorsSashSecondary;
    }
  }
}

@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointExtraLargeFrom (min-width: $tokenSizingBreakpointLarge2XFrom);

.root {
  --margin: 0 -$tokenSpacingDefault;

  align-items: center;
  background-color: $tokenColorsBrandLight5X;
  display: flex;
  flex-direction: row;
  gap: $tokenSpacingDefault;
  justify-content: center;
  margin: var(--margin);
  padding: 0 $tokenSpacingDefault;

  @media (--breakpointMediumFrom) {
    --margin: 0 calc((-1 * 12.5%));

    border-radius: $tokenSpacingDefault;
  }

  & p {
    display: flex;
    flex-wrap: wrap;
    font-size: $tokenFontSizesLarge1X;
    font-weight: $tokenFontWeightsSemiBold;
    margin: $tokenSpacingSmall2X 0;
    text-decoration: underline;
  }
}

@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMedium (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  background-color: $tokenColorsBrandTertiaryDefault;
  border: calc($tokenSpacingSmall4X / 2) solid $tokenColorsGreyscaleLight4X;
  border-radius: $tokenBorderRadiusLarge7X;
  /* stylelint-disable-next-line function-disallowed-list */
  box-shadow: 0 $tokenSpacingSmall3X $tokenSpacingSmall2X rgb(0 0 0 / 0.05);
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin: 0 8%;
  padding: $tokenSpacingDefault;
  padding-left: $tokenSpacingLarge1X;
}

& .searchForm {
  display: grid;
  gap: $tokenSpacingSmall2X;
  grid-template-columns: 1fr 1fr 1.4fr 1fr auto;

  & [data-faux-role='formField'] {
    margin-bottom: 0;

    @media (--breakpointMedium) {
      & input {
        height: $tokenSpacingLarge4X;
      }
    }
  }
}

@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: $tokenSpacingDefault;
  text-align: center;

  & h3 {
    text-align: center;
  }
}

.container {
  margin-top: $tokenSpacingDefault;
}
